import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';

import BrFilter from '@components/common/BrFilter';
import BrButton from '@components/common/BrButton';

import { formatPhone } from '@helpers/phone';

import ImtuTransaction from '@root/models/ImtuTransaction';

import RecentTxn from './components/RecentTxn';

interface Props {
  txns: ImtuTransaction[];
  onItemClick(id: string): void;
  onStartTopup?(): void;
  onSeeAllClick?(): void;
}

const RecentActivitySection: React.FC<Props> = (props) => {
  const { txns, onItemClick, onStartTopup, onSeeAllClick } = props;

  const [selectedFilter, setSelectedFilter] = useState<'all' | 'subscriptions'>('all');

  const { t } = useTranslation();

  const filterOptions = [
    { value: 'all', title: t('All') },
    {
      value: 'subscriptions',
      title: t('Subscriptions'),
    },
  ];

  const handleFilterItemSelect = (values: Array<'all' | 'subscriptions'>) => {
    if (values) {
      setSelectedFilter(values[0]);
    }
  };

  const renderRecentTxnItem = (
    txn: ImtuTransaction,
    idx: number,
    txnsArr: ImtuTransaction[],
  ) => {
    return (
      txn.recipientAmount &&
      txn.recipientCurrency &&
      txn.carrier?.name && (
        <RecentTxn
          key={txn.id}
          promoText={txn.carrierPromos?.[0].title}
          phone={formatPhone(txn.recipientPhoneNumber, txn.recipientCountryCode)}
          senderAmount={txn.status === 'scheduled' ? undefined : txn.senderAmount}
          senderCurrency={txn.senderCurrency}
          recipientAmount={txn.recipientAmount}
          recipientCurrency={txn.recipientCurrency}
          carrierName={txn.carrier.name}
          recipientCountryCode={txn.recipientCountryCode}
          onClickProps={
            txn.status === 'scheduled' ? undefined : { onClick: onItemClick, id: txn.id }
          }
          hasSeparateLine={txnsArr.length - 1 !== idx}
          additionalText={
            /* eslint-disable @typescript-eslint/indent */
            txn.status === 'scheduled' && txn.repeatInfo?.launch_at
              ? t('Scheduled on {{date}}', {
                  date: format(txn.repeatInfo.launch_at, 'MMM dd, yyyy'),
                })
              : undefined
            /* eslint-disable @typescript-eslint/indent */
          }
        />
      )
    );
  };

  return (
    <div className="bg-color/surface rounded-default py-default px-xlarge md:px-default">
      <div className="flex justify-between items-center">
        <div className="text-headers/header-4">{t('My top up activity')}</div>
        {onSeeAllClick && selectedFilter === 'all' && (
          <BrButton text={t('See all')} onClick={onSeeAllClick} cmpType="link" />
        )}
      </div>
      <div className="py-default">
        <BrFilter
          options={filterOptions}
          onItemSelect={handleFilterItemSelect}
          defaultValue={selectedFilter}
          color="Gray"
          shouldFallbackToDefault
        />
      </div>
      <div className="space-y-middle">
        {selectedFilter === 'all' && txns.map(renderRecentTxnItem)}

        {selectedFilter === 'subscriptions' &&
          (txns.filter((txn) => txn.status === 'scheduled').length ? (
            txns.filter((txn) => txn.status === 'scheduled').map(renderRecentTxnItem)
          ) : (
            // TODO: replace with Not found info component https://www.figma.com/design/K8DBHEsr9QXbXIATpi0us1/BR6-**-Design-System?node-id=3990-10162&t=TbIHhVIYjnWwaez1-0
            <div className="text-center space-y-small">
              <div>{t('No subscriptions')}</div>
              <div>
                {t(
                  'Save time on future top up with our automatic top up service, you can manage it anytime.',
                )}
              </div>
              <BrButton
                size="middle"
                hasIconLeft
                iconName="send-top-up"
                text={t('Start top up')}
                className="m-auto"
                onClick={onStartTopup}
              />
            </div>
          ))}
      </div>
    </div>
  );
};

export default RecentActivitySection;
